<template>
    <div class="w-full pl-3 flex">
        <div class="w-full flex flex-col">
      <scroll-container height="450px">
      <div class="flex w-full flex-wrap">
            <div class="w-1/2 flex my-8" v-for="(employee, index) in anniversary"
            :key="index">

            <div class="flex">
              <img
                v-if="employee.photo != null"
                class="mr-2 rounded"
                style="width: 50px; height: 50px"
                :src="employee.photo"
              />
              <div
                style="height: 50px; width: 50px; border-radius: 5px;"
                class="text-blueCrayola border text-center font-semibold pt-3 mr-2"
                v-else
              >
                {{ $getInitials(`${employee.fname} ${employee.lname}`) }}
              </div>
            </div>

            <div class="w-full flex flex-col mr-3">
            <div class="text-base font-semibold capitalize">
                {{`${employee.fname} ${employee.lname}`}}
            </div>
            <div class="text-xs mt-1 font-black mr-3 text-blueCrayola">
                {{$DATEFORMAT(new Date(employee.resumptionDate), "MMMM dd, yyyy")}} -
                {{employee.totalYears}} Year(s)
            </div>
            </div>

            </div>



            </div>
      </scroll-container>
        </div>
    </div>
</template>

<script>
import ScrollContainer from "@/components/ScrollContainer";

export default {
    components: {
        ScrollContainer
    },

    props: {

        length: {
        type : String,
        default : '0',
        },

        anniversary: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            password: '',
        }
    },

    /* mounted() {
        for(let i = 0; i < this.birthdays.length; i++) {
        const keyNames = Object.keys(this.birthdays[i]);
        console.log(keyNames[0]);
        }
    } */
}
</script>
